














































































import Vue from "vue";

export default Vue.extend({
  name: "searchButton",
  //props: {
  //  msg: String
  //},
  created() {
    //const params = location.pathname.split("/");
    //(this as any).videoId = getParam("v");
  }
});
