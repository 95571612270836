




































































































import Vue from "vue";
import loading from "@/components/loading.vue";
import LinkShare from "@/components/LinkShare.vue";
import YoutubeIframe from "@/components/YoutubeIframe.vue";
import getYouTubeID from "get-youtube-id";

import { SET_VIDEO_ID } from "@/store/Video/mutations";

export default Vue.extend({
  name: "generateUrl",
  components: {
    loading,
    //vueSlider,
    LinkShare,
    YoutubeIframe
    //navLink
    //VueYoutube
  },
  data() {
    return {
      loadingStatus: false,
      showVideoStatus: false,
      validationErrorStatus: false,
      searchFormText: ""
    };
  },

  //props: {
  //  msg: String
  //},
  created() {
    //const params = location.pathname.split("/");
    //(this as any).videoId = getParam("v");
  },
  methods: {
    onClick() {
      const videoId = getYouTubeID(this.searchFormText);
      if (videoId) {
        this.validationErrorStatus = false;
        this.loadingStatus = true;
        const showVideo = this.showVideo;

        this.$store.commit(`video/${SET_VIDEO_ID}`, videoId);
        //showVideo();
        setTimeout(function() {
          showVideo();
        }, 300);
      } else {
        this.validationErrorStatus = true;
      }
    },
    showVideo() {
      this.showVideoStatus = true;
      this.loadingStatus = false;
    }
  }
});
