

























import Vue from "vue";
//import VueYoutube from "vue-youtube";
import { toSecond, toHHMMSS, getParam } from "@/util/index";
import VueHead from "vue-head";
import GenerateUrl from "@/components/GenerateUrl.vue";
import navLink from "@/components/navLink.vue";
import LinkShare from "@/components/LinkShare.vue";
import YoutubeIframe from "@/components/YoutubeIframe.vue";
import YoutubeEmbed from "@/components/YoutubeEmbed.vue";
import VueYoutube from "@/components/VueYoutube.vue";
//const YouTubeIframeLoader = require("youtube-iframe");
import YouTubeIframeLoader from "youtube-iframe";
import {
  SET_VIDEO_ID,
  SET_START_TIME,
  SET_END_TIME,
  SET_FULL_MODE,
  SET_AUTO_PLAY_STATUS,
  SET_WORD
} from "@/store/Video/mutations";
//Vue.use(VueYoutube);
Vue.use(VueHead);
export default Vue.extend({
  components: {
    GenerateUrl,
    navLink,
    LinkShare,
    YoutubeIframe,
    YoutubeEmbed,
    VueYoutube
  },
  data() {
    return {
      pathName: "",
      title: "Loop Youtube",
      timeupdater: Object,
      //description: "sdsdsdsdsd",
      videoId: "",
      word: "",
      autoPlay: "",
      playingTime: 0,
      start: 0,
      end: 0,
      videotime: 0
      //duration: 0,
      //currentLoopCount: 0
      //player: null,
      //playerVars: {
      //  autoplay: 1,
      //  start: 20,
      //  end: 30
      //}
    };
  },
  head: {
    title: function() {
      return {
        inner: (this as any).metaTitle
      };
    },
    meta: function() {
      return [
        { property: "og:site_name", content: (this as any).title },
        { property: "og:title", content: (this as any).metaTitle },
        { property: "og:description", content: (this as any).description },
        {
          p: "og:image",
          c: (this as any).img
        },
        { property: "og:image:type", content: "image/gif" },
        { property: "og:image:width", content: "500" },
        { property: "og:image:height", content: "376" }
        // ...
      ];
    }
    /*
    meta: [
      { name: "application-name", content: "Name of my application" },
      { name: "description", content: "A description of the page", id: "desc" }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "!!Content Title" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Content description less than 200 characters"
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Content Title" },
      { itemprop: "description", content: "Content Title" },
      // ...
      // Facebook / Open Graph
      //{ property: "fb:app_id", content: "123456789" },
      { property: "og:title", content: "Content 22333" },
      { property: "og:description", content: "yyyyyyyyy" },
      //{ property: "og:type", content: "website" },
      //{ property: "og:url", content: "https://media3.giphy.com/media/fBEDuhnVCiP16/giphy.gif" },
      // with shorthand
      {
        p: "og:image",
        c: "https://media3.giphy.com/media/fBEDuhnVCiP16/giphy.gif"
      },
      { property: "og:image:type", content: "image/gif" },
      { property: "og:image:width", content: "500" },
      { property: "og:image:height", content: "376" }
      // ...
    ]*/
  },
  //https://loop-youtube.netlify.app/?v=WIUH0lhsbL0&s=00:20&e=00:30
  created() {
    //console.log("location", location.pathname);
    this.pathName = location.pathname;
    this.$store.commit(`video/${SET_FULL_MODE}`, this.isPathNameEmbed);

    this.videoId = getParam("v");
    this.$store.commit(`video/${SET_VIDEO_ID}`, this.videoId);

    this.start = toSecond(getParam("s"));
    this.$store.commit(`video/${SET_START_TIME}`, this.start);

    this.end = toSecond(getParam("e"));
    this.$store.commit(`video/${SET_END_TIME}`, this.end);

    this.word = getParam("w");
    this.$store.commit(`video/${SET_WORD}`, this.word);

    this.autoPlay = getParam("autoplay");
    this.$store.commit(`video/${SET_AUTO_PLAY_STATUS}`, this.autoPlay);
  },
  computed: {
    isPathNameEmbed(): boolean {
      return this.pathName === "/embed" ? true : false;
    },
    isPathNameVy(): boolean {
      return this.pathName === "/vy" ? true : false;
    },
    metaTitle(): string {
      if ((!!this.start || this.start == 0) && !!this.end) {
        return `${this.title} | ${toHHMMSS(this.start)}~${toHHMMSS(this.end)}`;
      } else {
        return `${this.title}`;
      }
    },
    decodedWord(): string {
      return decodeURIComponent(this.word);
    },
    description(): string {
      return `${this.decodedWord} | ${toHHMMSS(this.start)}~${toHHMMSS(
        this.end
      )}`;
    },
    img(): string {
      return `https://i.ytimg.com/vi/${this.videoId}/mqdefault.jpg`;
    },
    playerVars(): any {
      return {
        //controls: 0,
        player3: Object,
        rel: 0,
        autoplay: 1,
        enablejsapi: 1,
        fs: 0,
        playsinline: 1,
        ivLoadPolicy2: 1,
        //playlist: this.videoId,
        //muted: 1,
        //loop: 1,
        start: this.start,
        end: this.end
      };
    },
    isParams(): boolean {
      return !!this.videoId && (!!this.start || this.start == 0) && !!this.end;
    }
  },
  methods: {}
});
