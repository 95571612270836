






























































































import Vue from "vue";
import ClipboardJS from "clipboard";
import { toHHMMSS } from "@/util/index";
///Users/sehwakim/docker/loop-youtube/src/assets/icon/facebook.svg
//import facebook from "../assets"
//import Facebook from "../assets/icon/facebook.svg";

//import Facebook2 from "~/assets/icon/iconmonstr-facebook-6.svg";

//import Facebook from "./facebook.svg";
//import DownloadIcon from '@/assets/images/icons/download.svg?component'

export default Vue.extend({
  name: "searchButton",
  components: {
    //Facebook2
  },
  data() {
    return {
      value: ""
    };
  },

  //props: {
  //  msg: String
  //},
  created() {
    //const params = location.pathname.split("/");
    //(this as any).videoId = getParam("v");
  },
  computed: {
    videoId(): string {
      return this.$store.getters["video/getVideoId"];
    },
    start(): string {
      return toHHMMSS(this.$store.getters["video/getStartTime"]);
    },
    //end(): number {
    //  return this.$store.getters["video/getEndTime"];
    //},
    end(): string {
      return toHHMMSS(this.$store.getters["video/getEndTime"]);
      //const end = this.$store.getters["video/getEndTime"];
      //if (end) {
      //  return end;
      //} else {
      //  return this.player.getDuration();
      //}
    },
    word(): string {
      return this.$store.getters["video/getWordTime"];
    },
    player(): any {
      return this.$store.getters["video/getPlayer"];
    }
    //shareLinkUrl() {
    //  return `https://l-youtube.com/watch?v=${this.videoId}&s=${this.start}&e=${this.end}`;
    //}
  },
  methods: {
    onClickTwitterShareButton() {
      window.open(
        "https://twitter.com/intent/tweet?text=[%EA%B3%B5%EC%9C%A0]%20" +
          encodeURIComponent(this.shareLinkUrl()) +
          "%20-%20" +
          encodeURIComponent(document.title),
        "twittersharedialog",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
      return false;
    },
    onClickFacebookShareButton() {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(this.shareLinkUrl()) +
          "&t=" +
          encodeURIComponent(document.title),
        "facebooksharedialog",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
    },

    onClick() {
      const clipboard = new ClipboardJS("#copy-button");
    },
    shareLinkUrl() {
      const title: string | null | undefined = "";
      const pathUrl = `watch?v=${this.videoId}&s=${this.start}&e=${this.end}`;
      history.pushState(null, title, pathUrl);
      return `https://l-youtube.com/${pathUrl}`;
    }
  }
});
